body {
    background-color: white;
    color: black;
   }
  
   .mainContainer{
     display: flex;
     flex-direction: row;
     width: 100%;
     min-height: 100vh;
   }
  
   .videoContainer{
     background-color: white;
     width: 100%;
     min-height: 100vh;
   }
  
   .detailsContainer{
     background-color: black;
     /* background-color: #4C12A1; */
     background-color: #EAEAEA;
     width: 48%;
     min-height: 100vh;
   }
  
   .header{
     width: 100%;
     /* height:0px; */
     background-color: black;
     background-color: #EAEAEA;
     color: white;
     color:#4C12A1;
     display: flex;
     align-items: center;
     justify-content: left;
   }
  
   hr.solid {
    border-top: 1px solid #bbb;
  }
   .detailsBodyContainer{
     padding:20px
   }
   .userName{
     font-size: 20px;
     font-weight: bold;
     color: white;
     color:#4C12A1;
     margin: -5px 0 10px 0;
   }
  
   .labelContainer{
     width: 100%;
     display: flex;
     flex-direction: row;
    margin-bottom: 20px;
   }
   
   .labelFlexContainer{
    display: flex;
   }
  
   /* .label{
     color:#C5C6D0;
       color:#4C12A1;
     font-size: 13px;
     margin-right: 5px;
   } */
  
   .connect_screen_button1{

    font-size: 1rem;
  
    background: #CD2686;
  
    background: #4C12A1;
  
    color:#FFFFFF;
  
    /* font-weight: 900; */
  
    /* border:3px solid #FFFFFF; */
  
    border-radius: 40px;
  
    padding:1% 2%;
  
    width:80px;
  
    text-align: center;
  
    /* margin:2rem 0 2rem 0; */
  
    transition: all .2s ease-in-out;
  
    /* letter-spacing: -0.125rem; */
  
  
  
    /* position: absolute;
  
    bottom: 0px; */
  
  
  
    
  
  }
  
  .connect_screen_button1:hover{
  
    cursor: pointer;
  
    transform: scale(0.9);
  
  }

   .userDetails{
     color:white;
       color:#4C12A1;
     font-size: 14px;
     font-weight: bold;
     margin-right: 12px;
   }
  
   .cardContainer{
     width: 90%;
     display: flex;
    flex-wrap: wrap;
    color: white;
      color:#4C12A1;
    justify-content: space-between;
   }
  
   .cardDiv{
     padding: 5px;
     border: 1px solid #0289b9 ;
      border: 1px solid #CD2686 ;

     width: 31%;
     border-radius: 5px;
     height: 200px;
   }
  
   .cardTitle{
     font-size: 14px;
    color: #0289b9;
    color:#CD2686;
    font-weight: bold;
    margin-bottom: 10px;
   }
  
   .cardContent{
     font-size: 14px;
     margin-bottom: 10px;
   }
  